export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (localStorage.getItem('isLogged')) {
          // Redirect to the home page instead
          next({
            path: "/",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "dashboard",
    component: () => import("../views/dashboard/index.vue"),
    meta: {
      title: "Dashboard",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (!localStorage.getItem('isLogged')) {
          // Redirect to the home page instead
          next({
            path: "/login",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/sesiones",
    name: "sesiones",
    component: () => import("../views/sessions/index.vue"),
    meta: {
      title: "Sesiones",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (!localStorage.getItem('isLogged')) {
          // Redirect to the home page instead
          next({
            path: "/login",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/gestion",
    name: "gestion",
    component: () => import("../views/management/index.vue"),
    meta: {
      title: "Gestion",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (!localStorage.getItem('isLogged')) {
          // Redirect to the home page instead
          next({
            path: "/login",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/bienestar",
    name: "bienestar",
    component: () => import("../views/wellbeing/index.vue"),
    meta: {
      title: "Bienestar",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (!localStorage.getItem('isLogged')) {
          // Redirect to the home page instead
          next({
            path: "/login",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/reportes",
    name: "reportes",
    component: () => import("../views/report/index.vue"),
    meta: {
      title: "Reporte",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (!localStorage.getItem('isLogged')) {
          // Redirect to the home page instead
          next({
            path: "/login",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/reviews",
    name: "reviews",
    component: () => import("../views/review/index.vue"),
    meta: {
      title: "Review",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (!localStorage.getItem('isLogged')) {
          // Redirect to the home page instead
          next({
            path: "/login",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/perfil",
    name: "perfil",
    component: () => import("../views/account/profile.vue"),
    meta: {
      title: "Perfil",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (!localStorage.getItem('isLogged')) {
          // Redirect to the home page instead
          next({
            path: "/login",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
];